import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180 180"
    width={100}
    height={100}
    {...props}
  >
    <mask id="a">
      <rect width={180} height={180} rx={0} ry={0} fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <g transform="translate(0 66)">
        <g opacity={0.9} stroke="#2A3544">
          <path
            d="M38 12c-2.954 11.697-19.904 6.666-23.369 18.009C11.167 41.352 22.657 50 32.155 50"
            strokeWidth={6}
          />
          <path
            d="M150 55c8.394 3.486 20.102-7.594 16-16.5-4.102-8.906-16-6.694-16-19.305"
            strokeWidth={4}
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138 6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h19a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-19ZM21 37a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H23a2 2 0 0 1-2-2V37Zm115 7a2 2 0 0 1 2-2h19a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-19a2 2 0 0 1-2-2V44Z"
          fill="#273951"
        />
        <mask
          id="b"
          
          maskUnits="userSpaceOnUse"
          x={21}
          y={6}
          width={138}
          height={58}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138 6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h19a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-19ZM21 37a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H23a2 2 0 0 1-2-2V37Zm115 7a2 2 0 0 1 2-2h19a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-19a2 2 0 0 1-2-2V44Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#b)">
          <path fill="#7E57C2" d="M0 0h180v76H0z" />
        </g>
      </g>
      <g transform="translate(41)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.8 28.397c-7.811-7.81-7.811-20.474 0-28.284l13.434 13.435 6.453-6.453a4.001 4.001 0 0 1 6.275-4.861 4 4 0 0 1-4.86 6.275l-6.454 6.453 13.435 13.435c-5.45 5.45-13.263 7.097-20.142 4.94v18.776h-4V31.58a20.02 20.02 0 0 1-4.142-3.184Z"
          fill="#E1E6E8"
        />
        <mask
          id="c"
          
          maskUnits="userSpaceOnUse"
          x={37}
          y={0}
          width={36}
          height={53}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.8 28.397c-7.811-7.81-7.811-20.474 0-28.284l13.434 13.435 6.453-6.453a4.001 4.001 0 0 1 6.275-4.861 4 4 0 0 1-4.86 6.275l-6.454 6.453 13.435 13.435c-5.45 5.45-13.263 7.097-20.142 4.94v18.776h-4V31.58a20.02 20.02 0 0 1-4.142-3.184Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#c)">
          <path fill="#7E57C2" d="M0 0h100v52H0z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.799.113c-7.81 7.81-7.81 20.474 0 28.284 7.81 7.81 20.474 7.81 28.284 0"
            fill="#fff"
            fillOpacity={0.2}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.305 7.89a4 4 0 1 0 5.657-5.656 4 4 0 0 0-5.657 5.657Z"
            fill="#fff"
            fillOpacity={0.8}
          />
        </g>
      </g>
      <g transform="translate(25 44)">
        <rect width={130} height={120} rx={18} fill="#0076DE" />
        <mask
          id="d"
          
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={130}
          height={120}
        >
          <rect width={130} height={120} rx={18} fill="#fff" />
        </mask>
        <g mask="url(#d)">
          <path fill="#5E35B1" d="M-2-2h134v124H-2z" />
        </g>
      </g>
      <g transform="translate(52 124)" fillOpacity={0.6}>
        <rect x={28} y={10} width={6} height={14} rx={2} />
        <rect x={14} y={10} width={6} height={14} rx={2} />
        <rect x={42} y={10} width={6} height={14} rx={2} />
        <rect x={56} y={10} width={6} height={14} rx={2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90 124c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24Z"
        fill="#fff"
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90 120c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20Z"
        fillOpacity={0.8}
      />
      <path
        d="M102.573 91.815a1 1 0 1 0 1.675-1.093l-1.675 1.093Zm-2.991-5.86a1 1 0 1 0-1.129 1.652l1.129-1.652Zm-5.225-.313a1 1 0 0 0 .58-1.914l-.58 1.914ZM83.72 84.198a1 1 0 0 0 .739 1.858l-.74-1.858Zm-3.96 4.84a1 1 0 1 0-1.366-1.46l1.365 1.46Zm-5.304 4.067a1 1 0 1 0 1.828.812l-1.828-.812Zm29.79-2.383a17.086 17.086 0 0 0-4.665-4.767l-1.129 1.652a15.087 15.087 0 0 1 4.12 4.208l1.675-1.093Zm-9.31-6.994A16.999 16.999 0 0 0 90 83v2c1.517 0 2.98.225 4.357.642l.58-1.914ZM90 83c-2.216 0-4.335.425-6.28 1.198l.74 1.858A14.958 14.958 0 0 1 90 85v-2Zm-11.605 4.578a17.04 17.04 0 0 0-3.938 5.527l1.828.812a15.04 15.04 0 0 1 3.475-4.878l-1.365-1.461Z"
        fill="#fff"
        fillOpacity={0.9}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90 110c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z"
        fill="#C6080C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90 104a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        fill="#EE9337"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90 101a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="#F5F94F"
      />
    </g>
  </svg>
)

export default SvgComponent
