import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180 180"
    width={100}
    height={100}
    {...props}
  >
    <mask id="a">
      <rect width={180} height={180} rx={0} ry={0} fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <g transform="translate(0 66)">
        <g opacity={0.9} stroke="#2A3544">
          <path
            d="M38 12c-2.954 11.697-19.904 6.666-23.369 18.009C11.167 41.352 22.657 50 32.155 50"
            strokeWidth={6}
          />
          <path
            d="M150 55c8.394 3.486 20.102-7.594 16-16.5-4.102-8.906-16-6.694-16-19.305"
            strokeWidth={4}
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138 6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h19a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-19ZM21 37a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H23a2 2 0 0 1-2-2V37Zm115 7a2 2 0 0 1 2-2h19a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-19a2 2 0 0 1-2-2V44Z"
          fill="#273951"
        />
        <mask
          id="b"
          
          maskUnits="userSpaceOnUse"
          x={21}
          y={6}
          width={138}
          height={58}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138 6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h19a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-19ZM21 37a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H23a2 2 0 0 1-2-2V37Zm115 7a2 2 0 0 1 2-2h19a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-19a2 2 0 0 1-2-2V44Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#b)">
          <path fill="#66BB6A" d="M0 0h180v76H0z" />
        </g>
      </g>
      <g transform="translate(41)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48 0c-8.837 0-16 7.163-16 16v16a8 8 0 0 0 8 8H23a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h54a1 1 0 0 0 1-1V41a1 1 0 0 0-1-1H60a8 8 0 0 0 8-8V16c0-8.837-7.163-16-16-16h-4Z"
          fill="#59C4FF"
        />
        <mask
          id="c"
          
          maskUnits="userSpaceOnUse"
          x={22}
          y={0}
          width={56}
          height={52}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48 0c-8.837 0-16 7.163-16 16v16a8 8 0 0 0 8 8H23a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h54a1 1 0 0 0 1-1V41a1 1 0 0 0-1-1H60a8 8 0 0 0 8-8V16c0-8.837-7.163-16-16-16h-4Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#c)">
          <path fill="#66BB6A" d="M0 0h100v52H0z" />
          <path fill="#fff" fillOpacity={0.4} d="M20-3h60v43H20z" />
          <path
            d="M49 3.5c4.931 0 9.366 2.128 12.435 5.516"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m49.828 26-9-9L38 19.828l10 10V40h4V29.97l10.142-10.142L59.314 17l-9 9h-.486Z"
            fill="#fff"
            fillOpacity={0.8}
          />
        </g>
      </g>
      <g transform="translate(25 44)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 102V68.852a40 40 0 0 1 2.28-13.313l17.481-43.53A18 18 0 0 1 36.735 0h56.53a18.001 18.001 0 0 1 16.974 12.01l17.481 43.529A40.006 40.006 0 0 1 130 68.852V102c0 9.941-8.059 18-18 18H18c-9.941 0-18-8.059-18-18Z"
          fill="#0076DE"
        />
        <mask
          id="d"
          
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={130}
          height={120}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 102V68.852a40 40 0 0 1 2.28-13.313l17.481-43.53A18 18 0 0 1 36.735 0h56.53a18.001 18.001 0 0 1 16.974 12.01l17.481 43.529A40.006 40.006 0 0 1 130 68.852V102c0 9.941-8.059 18-18 18H18c-9.941 0-18-8.059-18-18Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#d)">
          <path fill="#43A047" d="M-2-2h134v124H-2z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.88 128.092c4.362-30.69 13.058-4.558 33.986-9.968 9.246-2.39 1.775-12.828 8.671-17.352 5.825-3.822 13.763-.743 20.436-3.214 9.629-3.567 9.502-10.823 18.474-11.907 8.485-1.025 17.374 2.354 25.931 1.084 16.151-2.395 9.529-34.024 21.64-39.61 12.111-5.586 32.05 4.06 34.66 19.875 2.544 15.417-14.458 72.78-14.458 72.78H.88"
            fillOpacity={0.2}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.927 128c37.677 0 41.6-14.75 60.138-21.507 18.538-6.758 34.781 10.721 41.013-6.26 6.232-16.98 6.198 35.948 6.198 35.948H45.926V128Z"
            fillOpacity={0.1}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 10.867c11.733-1.12 7.962 11.94 12.597 11.94 1.85 0 4.028-8.458 7.386-8.458 2.455 0 1.992 5.494 7.322 4.913 5.002-.545 4.07-3.993 6.597-3.993 5.443 0 3.385 20.195 7.742 20.195s5.73-14.796 7.647-20.648C59.718 7.412 74.263 3.262 63.891.32H7.714"
            fillOpacity={0.4}
          />
        </g>
      </g>
      <rect
        x={24}
        y={6}
        width={27}
        height={8}
        rx={4}
        fillOpacity={0.8}
        transform="translate(52 124)"
      />
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M66 120c10.37 0 18.898-7.893 19.901-18h41.515a5.001 5.001 0 0 0 9.584-2 5 5 0 0 0-9.584-2H85.901C84.898 87.893 76.371 80 66 80c-11.046 0-20 8.954-20 20s8.954 20 20 20Z"
          fillOpacity={0.2}
        />
        <path d="M132 102a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="#fff" />
        <path
          d="M66 116c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16Z"
          fillOpacity={0.6}
        />
        <path d="M72 95a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" fill="#fff" />
      </g>
    </g>
  </svg>
)

export default SvgComponent
