import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180 180"
    width={100}
    height={100}
    {...props}
  >
    <mask id="a">
      <rect width={180} height={180} rx={0} ry={0} fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <g transform="translate(0 66)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.436 10.184C134 11.04 134 12.16 134 14.4v47.2c0 2.24 0 3.36.436 4.216a3.996 3.996 0 0 0 1.748 1.748c.856.436 1.976.436 4.216.436h23.2c2.24 0 3.36 0 4.216-.436a3.996 3.996 0 0 0 1.748-1.748C170 64.96 170 63.84 170 61.6v-8.606c1.35-.018 2.161-.096 2.816-.43a3.996 3.996 0 0 0 1.748-1.748C175 49.96 175 48.84 175 46.6V29.4c0-2.24 0-3.36-.436-4.216a3.996 3.996 0 0 0-1.748-1.748c-.655-.334-1.466-.412-2.816-.43V14.4c0-2.24 0-3.36-.436-4.216a3.996 3.996 0 0 0-1.748-1.748C166.96 8 165.84 8 163.6 8h-23.2c-2.24 0-3.36 0-4.216.436a3.996 3.996 0 0 0-1.748 1.748Zm-114 7C20 18.04 20 19.16 20 21.4V31h-3.6c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748C10 34.04 10 35.16 10 37.4v17.2c0 2.24 0 3.36.436 4.216a4 4 0 0 0 1.748 1.748C13.04 61 14.16 61 16.4 61h23.2c2.24 0 3.36 0 4.216-.436a4 4 0 0 0 1.748-1.748C46 57.96 46 56.84 46 54.6V21.4c0-2.24 0-3.36-.436-4.216a4 4 0 0 0-1.748-1.748C42.96 15 41.84 15 39.6 15H26.4c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748Z"
          fill="#0076DE"
        />
        <mask
          id="b"
          
          maskUnits="userSpaceOnUse"
          x={10}
          y={8}
          width={165}
          height={60}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M134.436 10.184C134 11.04 134 12.16 134 14.4v47.2c0 2.24 0 3.36.436 4.216a3.996 3.996 0 0 0 1.748 1.748c.856.436 1.976.436 4.216.436h23.2c2.24 0 3.36 0 4.216-.436a3.996 3.996 0 0 0 1.748-1.748C170 64.96 170 63.84 170 61.6v-8.606c1.35-.018 2.161-.096 2.816-.43a3.996 3.996 0 0 0 1.748-1.748C175 49.96 175 48.84 175 46.6V29.4c0-2.24 0-3.36-.436-4.216a3.996 3.996 0 0 0-1.748-1.748c-.655-.334-1.466-.412-2.816-.43V14.4c0-2.24 0-3.36-.436-4.216a3.996 3.996 0 0 0-1.748-1.748C166.96 8 165.84 8 163.6 8h-23.2c-2.24 0-3.36 0-4.216.436a3.996 3.996 0 0 0-1.748 1.748Zm-114 7C20 18.04 20 19.16 20 21.4V31h-3.6c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748C10 34.04 10 35.16 10 37.4v17.2c0 2.24 0 3.36.436 4.216a4 4 0 0 0 1.748 1.748C13.04 61 14.16 61 16.4 61h23.2c2.24 0 3.36 0 4.216-.436a4 4 0 0 0 1.748-1.748C46 57.96 46 56.84 46 54.6V21.4c0-2.24 0-3.36-.436-4.216a4 4 0 0 0-1.748-1.748C42.96 15 41.84 15 39.6 15H26.4c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#b)">
          <path fill="#7E57C2" d="M0 0h180v76H0z" />
          <path fillOpacity={0.1} d="M0 47h180v29H0z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M161 25a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM161 41a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
            fill="#fff"
            fillOpacity={0.6}
          />
        </g>
      </g>
      <g transform="translate(41)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m53.568 39 1.976-4.615-6.191-10.675 2.895-10.626-2.894-.79-3.225 11.837 6.05 10.432L50.089 39H38v13h24V39h-8.432Z"
          fill="#E6E6E6"
        />
        <mask
          id="c"
          
          maskUnits="userSpaceOnUse"
          x={38}
          y={12}
          width={24}
          height={40}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m53.568 39 1.976-4.615-6.191-10.675 2.895-10.626-2.894-.79-3.225 11.837 6.05 10.432L50.089 39H38v13h24V39h-8.432Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#c)">
          <path fill="#7E57C2" d="M0 0h100v52H0z" />
          <path fill="#fff" fillOpacity={0.2} d="M38 39h24v13H38z" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
          fill="#FFE65C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
          fill="#fff"
        />
      </g>
      <g transform="translate(25 44)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 18C0 8.059 8.059 0 18 0h94c9.941 0 18 8.059 18 18v27.148c0 4.535-.771 9.037-2.28 13.313l-17.481 49.53A18.002 18.002 0 0 1 93.265 120h-56.53a18 18 0 0 1-16.974-12.009l-17.48-49.53A40 40 0 0 1 0 45.148V18Z"
          fill="#0076DE"
        />
        <mask
          id="d"
          
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={130}
          height={120}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 18C0 8.059 8.059 0 18 0h94c9.941 0 18 8.059 18 18v27.148c0 4.535-.771 9.037-2.28 13.313l-17.481 49.53A18.002 18.002 0 0 1 93.265 120h-56.53a18 18 0 0 1-16.974-12.009l-17.48-49.53A40 40 0 0 1 0 45.148V18Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#d)">
          <path fill="#5E35B1" d="M-2-2h134v124H-2z" />
        </g>
      </g>
      <g transform="translate(52 124)">
        <rect x={4} y={5} width={68} height={22} rx={5} fillOpacity={0.2} />
        <rect x={8} y={9} width={60} height={14} rx={2} fillOpacity={0.6} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m20 17 6-8H14l6 8ZM32 17l6-8H26l6 8ZM44 17l6-8H38l6 8ZM56 17l6-8H50l6 8Z"
          fill="#E1E6E8"
        />
      </g>
      <g fill="#FF5353" fillOpacity={0.8}>
        <path d="M67.27 85.68c-2.548.133-4.965 2.242-6.25 4.153-1.484-1.765-4.102-3.61-6.65-3.477-5.482.287-8.855 3.803-8.63 8.104.3 5.722 4.884 8.886 9.696 12.245 1.716 1.148 5.005 4.144 5.427 4.816.422.672 2.14.607 2.58-.135.439-.741 3.303-4.036 4.892-5.357 4.432-3.843 8.663-7.47 8.363-13.192-.225-4.3-3.947-7.444-9.429-7.157ZM125.63 86.356c-2.547-.134-5.171 1.711-6.65 3.477-1.29-1.91-3.702-4.02-6.25-4.153-5.48-.287-9.203 2.856-9.428 7.157-.3 5.722 3.928 9.349 8.362 13.192 1.587 1.321 4.544 4.645 4.894 5.357.349.713 2.065.827 2.58.135.513-.691 3.707-3.668 5.425-4.816 4.81-3.359 9.396-6.523 9.696-12.245.226-4.301-3.147-7.817-8.63-8.104Z" />
      </g>
    </g>
  </svg>
)

export default SvgComponent
