import Avatar1 from "../../components/Avatar/Avatar1";
import Avatar2 from "../../components/Avatar/Avatar2";
import Avatar3 from "../../components/Avatar/Avatar3";
import Avatar4 from "../../components/Avatar/Avatar4";
import Avatar5 from "../../components/Avatar/Avatar5";
import Avatar6 from "../../components/Avatar/Avatar6";
import Avatar7 from "../../components/Avatar/Avatar7";
import Avatar8 from "../../components/Avatar/Avatar8";
import Avatar9 from "../../components/Avatar/Avatar9";

export const avatars = [Avatar1,Avatar2,Avatar3,Avatar4,Avatar5,Avatar6,Avatar7,Avatar8,Avatar9];

export const CATEGORIES = [
  {
    id: 1,
    text: "FILTER_TAB_ALL",
    path: "?type=all",
  },
  {
    id: 2,
    text: "FILTER_TAB_GAVA",
    path: "?type=genzers-avatars",
  },
  {
    id: 16,
    text: "FILTER_TAB_AUCTION",
    path: "../auction?type=live",
  },
  {
    id: 3,
    text: "FILTER_TAB_AVA",
    path: "?type=avatars",
  },
  {
    id: 13,
    text: "FILTER_TAB_ART",
    path: "?type=art-media",
  },
  {
    id: 14,
    text: "ARCHITECUTRE",
    path: "?type=architecture",
  },
  {
    id: 4,
    text: "FILTER_TAB_COLL",
    path: "?type=collectibles",
  },
  {
    id: 5,
    text: "FILTER_TAB_VIRTUALW",
    path: "?type=virtual-worlds",
  },
  {
    id: 6,
    text: "FILTER_TAB_GAMING",
    path: "?type=gaming-assets",
  },
  {
    id: 7,
    text: "FILTER_TAB_MEMES",
    path: "?type=memes-poetry",
  },
  {
    id: 8,
    text: "FILTER_TAB_VIRTUALF",
    path: "?type=virtual-fashion",
  },
  {
    id: 15,
    text: "FILTER_TAB_SPORTS",
    path: "?type=sports-moments",
  },
  {
    id: 9,
    text: "FILTER_TAB_MUSIC",
    path: "?type=music-pop",
  },
  {
    id: 10,
    text: "FILTER_TAB_PICS",
    path: "?type=pictures-photos",
  },
  {
    id: 11,
    text: "FILTER_TAB_VID",
    path: "?type=video-animation",
  },
  {
    id: 12,
    text: "FILTER_TAB_OTHER",
    path: "?type=other",
  },
];

export const getHoursBeforeTime = (timeFromDB) => {
    if(timeFromDB){
        let time = new Date(timeFromDB);
        let timeNow = new Date(Date.now());
        let diff = timeNow - time;
        let hourDiff = Math.floor(diff / 1000 / 60 / 60);
        return hourDiff;
    }
    return "";
}

export const isAuctionEndDatePassed = (timeFromDB) => { 
    if(timeFromDB){
        let time = new Date(timeFromDB);
        let timeNow = new Date(Date.now());
        return time <= timeNow;
    }
    return true;
}

export const formatpTime = (ptime, t) => {
    let time = "";
    if (ptime) {
      const date = new Date(ptime).getTime();
      const newDate = new Date().getTime();
      const differenceTime = (newDate - date) / (1000 * 3600);
      if (differenceTime > 24) {
        const differenceTimeInDay = (newDate - date) / (1000 * 3600 * 24);
        time += new String(Math.floor(differenceTimeInDay));
        if (Math.floor(differenceTimeInDay) == 1) {
          time += " " + t("TIME_DAY_AGO");
        } else {
          time += " " + t("TIME_DAYS_AGO");
        }
      } else {
        time += new String(Math.floor(differenceTime));
        if (Math.floor(differenceTime) == 1) {
          time += " " + t("TIME_HOUR_AGO");
        } else {
          time += " " + t("TIME_HOURS_AGO");
        }
      }
    }
    return time;
  };

export const compareFuncForAssetsTimeAsc = (a, b) => {
  let aT = new Date(a.pTime);
  let bT = new Date(b.pTime);
  if (bT > aT) {
    return 1;
  } else if (bT < aT) {
    return -1;
  }
  return 0;
}

export const compareFuncForAssetsTimeDesc = (a, b) => {
  let aT = new Date(a.pTime);
  let bT = new Date(b.pTime);
  if (bT > aT) {
    return -1;
  } else if (bT < aT) {
    return 1;
  }
  return 0;
}

export const compareFuncForNotificationsTimeAsc = (a, b) => {
  let aT = new Date(a.time);
  let bT = new Date(b.time);
  if (bT > aT) {
    return 1;
  } else if (bT < aT) {
    return -1;
  }
  return 0;
}

export const languageFlagMap = {
  "العربية": "sa",
  "বাংলা": "in",
  "български": "bg",
  "中文": "cn",
  "Hrvatski": "hr",
  "Čeština": "cz",
  "Dansk": "dk",
  "Nederlands": "nl",
  "english": "gb",
  "Eesti keel": "ee",
  "Suomi": "fi",
  "Français": "fr",
  "Deutsch": "de",
  "Ελληνικά": "gr",
  "ગુજરાતી": "in",
  "ಕನ್ನಡ": "in",
  "മലയാളം": "in",
  "मराठी": "in",
  "ਪੰਜਾਬੀ": "in",
  "தமிழ்": "in",
  "తెలుగు": "in",
  "हिन्दी": "in",
  "Magyar": "hu",
  "Bahasa Indonesia": "id",
  "Italiano": "it",
  "日本語": "jp",
  "한국어": "kr",
  "Latviešu": "lv",
  "Lietuvių": "lt",
  "Norsk": "no",
  "Polski": "pl",
  "Português": "pt",
  "Română": "ro",
  "Српски": "rs",
  "Slovenčina": "sk",
  "Slovenščina": "si",
  "Español": "es",
  "Svenska": "se",
  "ภาษาไทย": "th",
  "Türkçe": "tr",
  "urdu": "pk",
  "Tiếng Việt": "vn",
  "other": "gb"
}