import {
  useState,
  useEffect,
  lazy,
  Suspense,
  useCallback,
  useRef,
} from "react";
// Material UI
import { ThemeProvider } from "@emotion/react";
import { Box, useMediaQuery } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";

// React Router
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import MpDataContext from "./Utils/Contexts/MpDataContext";
import { MP_HOST } from "./Utils/ApplicationConstants/Constants";
import OuterContext from "./Utils/Contexts/OuterContext";
// import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";
import io from "socket.io-client";
import useCustomTheme from "./hooks/useCustomTheme";
import SuspenseFallback from "./Pages/FallBack/SuspenseFallback";
import { CookiesProvider } from "react-cookie";
import "./App.css";

//import Home from "./Pages/Home/Home";
//import ChatMessages from "./Pages/Chat/ChatMessages";
//import Layout from "./components/Layout/Layout";
//import Footer from "./components/Footer/Footer";

const ChatMessages = lazy(() => import("./Pages/Chat/ChatMessages"));
const Home = lazy(() => import("./Pages/Home/Home"));
const Layout = lazy(() => import("./components/Layout/Layout"));
const Footer = lazy(() => import("./components/Footer/Footer"));

//Collections
//import IndividualNft from "./Pages/Collections/Individualnft";
//import Collections from "./Pages/Collections/Collections";

const ThirdWebWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ "./components/ThirdWebWrapper/ThirdWebWrapper"
  )
);

const IndividualNft = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Collections/Individualnft")
);
const Collections = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Collections/Collections")
);

//import AdminDashboard from "./Pages/AdminDashboard/Dashboard";
//import AffiliateDash from "./Pages/AffiliateDashboard/AffiliateDashboard";
//import L2Dashboard from "./Pages/L2Dashboard/L2Dashboard";

const AdminDashboard = lazy(() => import("./Pages/AdminDashboard/Dashboard"));
const AffiliateDash = lazy(() =>
  import("./Pages/AffiliateDashboard/AffiliateDashboard")
);
const L2Dashboard = lazy(() => import("./Pages/L2Dashboard/L2Dashboard"));

// Modules
const ArtCardDetails = lazy(() =>
  import(
    /* webpackPrefetch: true */ "./components/ArtCardDetails/ArtCardDetails"
  )
);
const AssetCardDetails = lazy(() =>
  import(
    /* webpackPrefetch: true */ "./components/AssetCardDetails/AssetCardDetails"
  )
);
const Auction = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Auction/Auction")
);
const Explore = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Explore/Explore")
);
const Favourites = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Favourites/Favourites")
);
const TrendingSellers = lazy(() =>
  import("./Pages/TrendingSellers/TrendingSellers")
);
const SellersDetails = lazy(() =>
  import("./Pages/SellerDetails/SellersDetails")
);
const UserProfile = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/UserProfile/UserProfile")
);
const TrendingCreators = lazy(() =>
  import("./Pages/TrendingCreators/TrendingCreators")
);
const ProfileInterface = lazy(() =>
  import("./components/ProfileInterface/ProfileInterface")
);
const KYCInterface = lazy(() =>
  import("./components/VerificationInterface/VerificationInterface")
);
const LanguageInterface = lazy(() =>
  import("./components/LanguageInterface/LanguageInterface")
);
const ThemeInterface = lazy(() =>
  import("./components/ThemeInterface/ThemeInterface")
);
const EditProfile = lazy(() =>
  import("./components/ProfileInterface/EditProfile")
);
const KYCPending = lazy(() =>
  import("./components/VerificationInterface/VerificationPending")
);
const KYCApproved = lazy(() =>
  import("./components/VerificationInterface/VerificationApproved")
);
const TermsAndCondition = lazy(() =>
  import("./Pages/Terms&Condition/TermsAndCondition")
);
const FAQ = lazy(() => import(/* webpackPrefetch: true */ "./Pages/FAQ/FAQ"));
const PrivacyPolicy = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/PrivacyPolicy/PrivacyPolicy")
);
const Layer2 = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Layer2/Layer2")
);
const ContactUs = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/ContactUs/ContactUs")
);
const CreateAssets = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/CreateAssets/CreateAssets")
);
const CreateList = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/CreateList/CreateList")
);
const ListNft = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/ListNft/ListNft")
);
const UserProfileId = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/UserProfileId/UserProfileId")
);
const AuctionCardDetails = lazy(() =>
  import(
    /* webpackPrefetch: true */ "./components/AuctionCardDetails/AuctionCardDetails"
  )
);
const CreatorsDetails = lazy(() =>
  import("./Pages/CreatorsDetails/CreatorsDetails")
);
const TrendingNFTs = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/TrendingNFTs/TrendingNFTs")
);
const FeaturedNFTs = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/FeaturedNFTs/FeaturedNFTs")
);
const Ranking = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Ranking/Ranking")
);
const Disclaimer = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Disclaimer/Disclaimer")
);
const Affiliates = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Affiliates/Affiliates")
);
const Merchandise = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Merchandise/Merchandise")
);
const ReferCreators = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/ReferCreators/ReferCreators")
);
const ReferBuyers = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/ReferBuyers/ReferBuyers")
);
const Assets = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Assets/Assets")
);
const Notifications = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Notifications/Notifications")
);
const UserLogin = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/UserLogin/UserLogin")
);
const UserRegister = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/UserRegister/UserRegister")
);
const Create = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Create/Create")
);
const CreateMulti = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/CreateMulti/CreateMulti")
);
const Chat = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/Chat/Chat")
);
const MintedCardDetails = lazy(() =>
  import("./components/MintedCard/MintedCardDetails")
);
const UnlockContent = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/UnlockContent/UnlockContent")
);
const AssetSold = lazy(() =>
  import(/* webpackPrefetch: true */ "./Pages/AssetSold/AssetSold")
);

//MDB
// const activeChainId = ChainId.Mainnet;
//const activeChainId = [1, 4, 137];

console.log("NODE_ENV", process.env.NODE_ENV);
console.log("Chain", process.env.REACT_APP_CHAIN_NAME);

const socket = io("https://msg.realmdb.com/");

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const { customTheme } = useCustomTheme(darkMode);

  const [mpData, setMpData] = useState({
    logo: "",
    favicon: "",
    name: "Maintenance",
    title: "NFT Marketplace Maintenance",
    description: "NFT Marketplace in Maintenance",
    mpHost: "fallback.buqo.io",
    header1: "NFT Marketplace",
    header2: "Maintenance",
    mpSU: "6384751da110d431f5fb5d17",
    mpFee: "200",
    aIdFee: "200",
    rIdFee: "50",
    mpHero: [],
    mpFeatured: [],
    mpVersion: "1",
    mpId: "0xE68445d70F7862AD33d9068dbbE5506D3d17d766",
    mpHome: "1",
    mpNav: "1",
    mpEmail: "",
    mpTwitter: "",
    mpYT: "",
    mpInsta: "",
    mpTik: "",
    mpSoc: "",
    mpBE: "db.realmdb.com",
    mpIMG: "onoz.io",
    showFeatured: false,
    showHero: false,
    allFeatured: false,
    allExplore: false,
    showExplore: false,
    allCollections: false,
    showCollections: false,
    allAuctions: false,
    showAuctions: false,
    hasAff: true,
    themeSwitch: true,
    orderbook: ["open", "L2", "shared", "own"],
    singleCard: false
  });

  const globalRef = useRef({});

  const BACKEND_VAR = process.env.REACT_APP_CF_BACKEND_VAR;
  const CF_SSL = process.env.REACT_APP_CF_SSL;

  const getOriginFees = useCallback((aID, data) => {
    let of = [];
    let total = 0;
    if (aID !== undefined && data?.aIdFee !== undefined) {
      of.push({
        account: `${aID}`,
        value: `${data?.aIdFee}`,
      });
      total += Number(data?.aIdFee);
    }
    if (data?.mpId !== undefined && data?.mpFee !== undefined) {
      of.push({
        account: `${data?.mpId}`,
        value: `${data?.mpFee}`,
      });
      total += Number(data?.mpFee);
    }
    if (data?.mgrId !== undefined && data?.mgrFee !== undefined) {
      of.push({
        account: `${data?.mgrId}`,
        value: `${data?.mgrFee}`,
      });
      total += Number(data?.mgrFee);
    }
    if (data?.maID !== undefined && data?.maFee !== undefined) {
      of.push({
        account: `${data?.maID}`,
        value: `${data?.maFee}`,
      });
      total += Number(data?.maFee);
    }
    return [of, total];
  }, []);

  const requestEth = useCallback(async () => {
    window.ETHUSD = await localStorage.getItem("ETHUSD");
    const CF_SSL = process.env.REACT_APP_CF_SSL;
    const mpHost = MP_HOST;
    await axios
      .get(`${CF_SSL}${mpHost}/ex/eth-usd2`)
      .then((ethData) => {
        const ethUsd = JSON.parse(JSON.stringify(ethData));
        console.log(ethUsd)
        const ETHUSD = ethUsd.data.result.ethusd;
        localStorage.setItem("ETHUSD", ETHUSD);
        window.ETHUSD = ETHUSD;
      })
      .catch(async (e) => {
        console.error(e);
        const CF_SSL = process.env.REACT_APP_CF_SSL;
        const mpHost = MP_HOST;
        await axios
          .get(`${CF_SSL}${mpHost}/ex/eth-usd`)
          .then((ethData) => {
            const ethUsd = JSON.parse(JSON.stringify(ethData));
            const ETHUSD = ethUsd.data.result.ethusd;
            localStorage.setItem("ETHUSD", ETHUSD);
            window.ETHUSD = ETHUSD;
          })
          .catch((e) => {
            console.error(e);
          });
      });
  }, []);

  const getOriginFeesIMX = useCallback((aID, data) => {
    let of = [];
    let total = 0;
    if (aID !== undefined && data?.aIdFee !== undefined) {
      of.push({
        recipient: `${aID}`,
        percentage: `${data?.aIdFee}`,
      });
      total += Number(data?.aIdFee);
    }
    if (data?.mpId !== undefined && data?.mpFee !== undefined) {
      of.push({
        recipient: `${data?.mpId}`,
        percentage: `${data?.mpFee}`,
      });
      total += Number(data?.mpFee);
    }
    if (data?.maID !== undefined && data?.maFee !== undefined) {
      of.push({
        recipient: `${data?.maID}`,
        percentage: `${data?.maFee}`,
      });
      total += Number(data?.maFee);
    }
    return [of, total];
  }, []);

  const getOriginFeesRari = useCallback((aID, data) => {
    let of = [];
    let total = 0;
    if (aID !== undefined && data?.aIdFee !== undefined) {
      of.push({
        account: `ETHEREUM:${aID}`,
        value: `${data?.aIdFee}`,
      });
      total += Number(data?.aIdFee);
    }
    if (data?.mpId !== undefined && data?.mpFee !== undefined) {
      of.push({
        account: `ETHEREUM:${data?.mpId}`,
        value: `${data?.mpFee}`,
      });
      total += Number(data?.mpFee);
    }
    if (data?.mgrId !== undefined && data?.mgrFee !== undefined) {
      of.push({
        account: `ETHEREUM:${data?.mgrId}`,
        value: `${data?.mgrFee}`,
      });
      total += Number(data?.mgrFee);
    }
    if (data?.maID !== undefined && data?.maFee !== undefined) {
      of.push({
        account: `ETHEREUM:${data?.maID}`,
        value: `${data?.maFee}`,
      });
      total += Number(data?.maFee);
    }
    return [of, total];
  }, []);

  const mpDataf = useCallback(async () => {
    let uData = await localStorage.getItem("userData");
    let uDataObject = await JSON.parse(uData);
    let aID = uDataObject?.aID;
    const mpHost = window.location.hostname;
    console.log("mpHost:", mpHost);
    if (mpHost === "localhost") {
      const url = `https://db.realmdb.com/api/kv/fallback`;
      console.log(url);
      await axios.get(url).then((res) => {
        setMpData(res?.data);
        console.log("mpData fallback:", res.data);
        let [originFees, originFeeTotal] = getOriginFees(aID, res?.data);
        let [originFeesIMX, originFeesIMXTotal] = getOriginFeesIMX(
          aID,
          res?.data
        );
        let [originFeesRari, originFeesRariTotal] = getOriginFeesRari(
          aID,
          res?.data
        );
        globalRef.current = {
          ...globalRef.current,
          originFees,
          originFeesIMX,
          originFeesRari,
          originFeeTotal,
          originFeesIMXTotal,
          originFeesRariTotal,
        };
      });
    } else {
      const url = `https://${mpHost}/kvdataapi?i=${mpHost}`;
      console.log("get from mpHost url:", url);
      await axios
        .get(url)
        .then((res) => {
          console.log("res", res);
          if (res?.data != null && res.data !== "") {
            setMpData(res?.data);
            console.log("mpData fetch", res.data);
            let [originFees, originFeeTotal] = getOriginFees(aID, res?.data);
            console.log("origin Fees Total", originFeeTotal);
            let [originFeesIMX, originFeesIMXTotal] = getOriginFeesIMX(
              aID,
              res?.data
            );
            let [originFeesRari, originFeesRariTotal] = getOriginFeesRari(
              aID,
              res?.data
            );
            globalRef.current = {
              ...globalRef.current,
              originFees,
              originFeesIMX,
              originFeesRari,
              originFeeTotal,
              originFeesIMXTotal,
              originFeesRariTotal,
            };
          } else {
            const url = `https://db.realmdb.com/api/kv/${mpHost}`;
            console.log("get from mpHost url:", url);
            axios.get(url).then((res) => {
              if (res?.data != null && res.data !== "") {
                setMpData(res?.data);
                console.log("mpData fetch", res.data);
                let [originFees, originFeeTotal] = getOriginFees(
                  aID,
                  res?.data
                );
                console.log("origin Fees Total", originFeeTotal);
                let [originFeesIMX, originFeesIMXTotal] = getOriginFeesIMX(
                  aID,
                  res?.data
                );
                let [originFeesRari, originFeesRariTotal] = getOriginFeesRari(
                  aID,
                  res?.data
                );
                globalRef.current = {
                  ...globalRef.current,
                  originFees,
                  originFeesIMX,
                  originFeesRari,
                  originFeeTotal,
                  originFeesIMXTotal,
                  originFeesRariTotal,
                };
              } else {
                let mp = {
                  logo: "https://onoz.io/public/maintenance",
                  favicon: "https://onoz.io/public/maintenance",
                  name: "Maintenance",
                  title: "NFT Marketplace Maintenance",
                  description: "NFT Marketplace in Maintenance",
                  mpHost: "fallback.buqo.io",
                  header1: "NFT Marketplace",
                  header2: "Maintenance",
                  mpSU: "6384751da110d431f5fb5d17",
                  mpFee: "200",
                  aIdFee: "200",
                  rIdFee: "50",
                  mpHero: [],
                  mpFeatured: [],
                  mpVersion: "1",
                  mpId: "0xE68445d70F7862AD33d9068dbbE5506D3d17d766",
                  mpHome: "1",
                  mpNav: "1",
                  mpEmail: "",
                  mpTwitter: "",
                  mpYT: "",
                  mpInsta: "",
                  mpTik: "",
                  mpSoc: "",
                  mpBE: "db.realmdb.com",
                  mpIMG: "onoz.io",
                  showFeatured: false,
                  showHero: false,
                  allFeatured: false,
                  allExplore: false,
                  showExplore: false,
                  allCollections: false,
                  showCollections: false,
                  allAuctions: false,
                  showAuctions: false,
                  hasAff: true,
                  themeSwitch: true,
                  orderbook: ["open", "L2", "shared", "own"],
                };
                setMpData(mp);
                let [originFees, originFeeTotal] = getOriginFees(aID, mp);
                let [originFeesIMX, originFeesIMXTotal] = getOriginFeesIMX(
                  aID,
                  mp
                );
                let [originFeesRari, originFeesRariTotal] = getOriginFeesRari(
                  aID,
                  mp
                );
                globalRef.current = {
                  ...globalRef.current,
                  originFees,
                  originFeesIMX,
                  originFeesRari,
                  originFeeTotal,
                  originFeesIMXTotal,
                  originFeesRariTotal,
                };
                console.log("string was empty");
              }
            });
          }
        })
        .catch((e) => {
          let mp = {
            logo: "https://onoz.io/public/maintenance",
            favicon: "https://onoz.io/public/maintenance",
            name: "Maintenance",
            title: "NFT Marketplace Maintenance",
            description: "NFT Marketplace in Maintenance",
            mpHost: "fallback.buqo.io",
            header1: "NFT Marketplace",
            header2: "Maintenance",
            mpSU: "6384751da110d431f5fb5d17",
            mpFee: "200",
            aIdFee: "200",
            rIdFee: "50",
            mpHero: "",
            mpFeatured: "",
            mpVersion: "1",
            mpId: "0xE68445d70F7862AD33d9068dbbE5506D3d17d766",
            mpHome: "1",
            mpNav: "1",
            mpEmail: "",
            mpTwitter: "",
            mpYT: "",
            mpInsta: "",
            mpTik: "",
            mpSoc: "",
            mpBE: "db.realmdb.com",
            mpIMG: "onoz.io",
            showFeatured: false,
            showHero: false,
            allFeatured: false,
            allExplore: false,
            showExplore: false,
            allCollections: false,
            showCollections: false,
            allAuctions: false,
            showAuctions: false,
            hasAff: true,
            themeSwitch: true,
            orderbook: ["open", "L2", "shared", "own"],
          };
          setMpData(mp);
          let [originFees, originFeeTotal] = getOriginFees(aID, mp);
          let [originFeesIMX, originFeesIMXTotal] = getOriginFeesIMX(aID, mp);
          let [originFeesRari, originFeesRariTotal] = getOriginFeesRari(
            aID,
            mp
          );
          globalRef.current = {
            ...globalRef.current,
            originFees,
            originFeesIMX,
            originFeesRari,
            originFeeTotal,
            originFeesIMXTotal,
            originFeesRariTotal,
          };
          console.log("catch", e);
        });
    }
  }, [getOriginFees, getOriginFeesIMX, getOriginFeesRari]);

  useEffect(() => {
    mpDataf();
    requestEth();
  }, []);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${CF_SSL}${MP_HOST}${BACKEND_VAR}gettext?domain=${window.location.hostname}&getAll=true`
        )
        .then((result) => {
          let textTypes = [];
          if (result) {
            const data = result.data;
            console.log(data);
            for (const datatype of data) {
              textTypes.push(datatype.type);
            }
            globalRef.current = {
              ...globalRef.current,
              textTypes: textTypes,
            };
          } else {
            globalRef.current = {
              ...globalRef.current,
              textTypes: textTypes,
            };
          }
        });
    };
    getData();
  }, []);

  const isMobile = useMediaQuery("(max-width:966px)");

  const handleDarkThemeSwitch = () => {
    localStorage.setItem("theme", "dark");
    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      setDarkMode(true);
    }
  };

  const handleLightThemeSwitch = () => {
    localStorage.setItem("theme", "light");
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      localStorage.setItem("theme", "light");
      setDarkMode(false);
    }
  };

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      setDarkMode(true);
    } else if (theme === "light") {
      localStorage.setItem("theme", "light");
      setDarkMode(false);
    }
  }, [customTheme]);

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <HelmetProvider>
        {/* <ThirdwebProvider desiredChainId={activeChainId}> */}
        <Helmet>
          <title>{mpData?.title ? mpData.title : "NFT Marketplace"}</title>
          <meta
            name="description"
            content={
              mpData?.description ? mpData.description : "NFT Marketplace"
            }
          ></meta>
          {mpData?.favicon && (
            <link
              rel="icon"
              type="image/png"
              href={mpData.favicon}
              sizes="32x32"
            />
          )}
        </Helmet>
        <ThemeProvider theme={customTheme}>
          <div
            style={{
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
              height: "100vh",
            }}
          >
            <CookiesProvider>
              <Box bgcolor={darkMode ? "#040404" : "#ffffff"}>
                <BrowserRouter>
                  <MpDataContext.Provider value={mpData}>
                    <OuterContext.Provider value={globalRef.current}>
                      <Layout
                        darkMode={darkMode}
                        socket={socket}
                        handleDarkThemeSwitch={handleDarkThemeSwitch}
                        handleLightThemeSwitch={handleLightThemeSwitch}
                        enableHamburgerMenu={true}
                        enableSidebar={mpData.mpNav === "3" ? true : false}
                      >
                        <Suspense fallback={<SuspenseFallback />}>
                          <Routes>
                            <Route
                              path="/"
                              element={
                                <Home darkMode={darkMode} mpData={mpData} />
                              }
                            />
                            <Route
                              path="/home"
                              element={
                                <Home darkMode={darkMode} mpData={mpData} />
                              }
                            />
                            <Route
                              path="/explore"
                              element={<Explore darkMode={darkMode} />}
                            />
                            <Route
                              path="/collections"
                              element={<Collections darkMode={darkMode} />}
                            />
                            <Route
                              path="/collection/:id"
                              element={<IndividualNft darkMode={darkMode} />}
                            />
                            <Route
                              path="/profile/notifications"
                              element={<Notifications darkMode={darkMode} />}
                            />
                            <Route
                              path="profile/chat"
                              element={
                                <Chat darkMode={darkMode} socket={socket} />
                              }
                            />
                            <Route
                              path="profile/chat/:id"
                              element={
                                <Chat darkMode={darkMode} socket={socket} />
                              }
                            />
                            <Route
                              path="profile/chat/mobile/:id"
                              element={
                                <ChatMessages
                                  darkMode={darkMode}
                                  socket={socket}
                                />
                              }
                            />
                            <Route
                              path="/auction"
                              element={<Auction darkMode={darkMode} />}
                            />
                            <Route
                              path="/explore/:id"
                              element={
                                <ArtCardDetails
                                  darkMode={darkMode}
                                  socket={socket}
                                />
                              }
                            />
                            <Route
                              path="/asset/:id"
                              element={
                                <AssetCardDetails
                                  darkMode={darkMode}
                                  socket={socket}
                                />
                              }
                            />
                            <Route
                              path="/minted"
                              element={
                                <MintedCardDetails darkMode={darkMode} />
                              }
                            />
                            <Route
                              path="/listNft"
                              element={
                                <ThirdWebWrapper>
                                  <ListNft darkMode={darkMode} />
                                </ThirdWebWrapper>
                              }
                            />
                            <Route
                              path="/disclaimer"
                              element={<Disclaimer darkMode={darkMode} />}
                            />
                            <Route
                              path="/trending-sellers"
                              element={<TrendingSellers darkMode={darkMode} />}
                            />
                            <Route
                              path="/affiliates"
                              element={<Affiliates darkMode={darkMode} />}
                            />
                            <Route
                              path="/login"
                              element={<UserLogin darkMode={darkMode} />}
                            />
                            <Route
                              path="/merchandise"
                              element={<Merchandise darkMode={darkMode} />}
                            />
                            <Route
                              path="/auction/:id"
                              element={
                                <AuctionCardDetails
                                  darkMode={darkMode}
                                  socket={socket}
                                />
                              }
                            />
                            <Route
                              path="/favourites"
                              element={<Favourites darkMode={darkMode} />}
                            />
                            <Route
                              path="/profile/favourites"
                              element={<Favourites darkMode={darkMode} />}
                            />
                            <Route
                              path="/profile/assets"
                              element={<Assets darkMode={darkMode} />}
                            />
                            <Route
                              path="/trending-nfts"
                              element={<FeaturedNFTs darkMode={darkMode} />}
                            />
                            <Route
                              path="/featured"
                              element={<FeaturedNFTs darkMode={darkMode} />}
                            />
                            <Route
                              path="/unlockableContent"
                              element={<UnlockContent darkMode={darkMode} />}
                            />
                            <Route
                              path="/trending-sellers/:id"
                              element={<SellersDetails darkMode={darkMode} />}
                            />
                            <Route
                              path="/user/:id"
                              element={<UserProfileId darkMode={darkMode} />}
                            />
                            <Route
                              path="/create-asset"
                              element={<CreateAssets darkMode={darkMode} />}
                            />
                            <Route
                              path="/create-list"
                              element={<CreateList darkMode={darkMode} />}
                            />
                            <Route
                              path="redirect"
                              element={<AssetSold darkMode={darkMode} />}
                            />
                            <Route
                              path="/ranking"
                              element={<Ranking darkMode={darkMode} />}
                            />
                            <Route
                              path="/register"
                              element={<UserRegister darkMode={darkMode} />}
                            />
                            <Route
                              path="/profile"
                              element={<UserProfile darkMode={darkMode} />}
                            >
                              <Route
                                path="dashboard"
                                element={<AdminDashboard darkMode={darkMode} />}
                              />
                              <Route
                                path="affiliate-dash"
                                element={<AffiliateDash darkMode={darkMode} />}
                              />
                              <Route
                                path="l2-dashboard"
                                element={<L2Dashboard darkMode={darkMode} />}
                              />
                              <Route
                                path="user-profile"
                                element={
                                  <ProfileInterface darkMode={darkMode} />
                                }
                              />
                              <Route
                                path="edit-profile"
                                element={<EditProfile darkMode={darkMode} />}
                              />
                              <Route
                                path="verification"
                                element={<KYCInterface darkMode={darkMode} />}
                              />
                              <Route
                                path="verification-pending"
                                element={<KYCPending darkMode={darkMode} />}
                              />
                              <Route
                                path="verification-approved"
                                element={<KYCApproved darkMode={darkMode} />}
                              />
                              <Route
                                path="language"
                                element={
                                  <LanguageInterface darkMode={darkMode} />
                                }
                              />
                              <Route
                                path="theme"
                                element={
                                  <ThemeInterface
                                    darkMode={darkMode}
                                    //setDarkMode={setDarkMode}
                                    handleDarkThemeSwitch={
                                      handleDarkThemeSwitch
                                    }
                                    handleLightThemeSwitch={
                                      handleLightThemeSwitch
                                    }
                                  />
                                }
                              />
                            </Route>
                            <Route
                              path="/trending-creators"
                              element={<TrendingCreators darkMode={darkMode} />}
                            />
                            <Route
                              path="/create"
                              element={<Create darkMode={darkMode} />}
                            />
                            <Route
                              path="/create-multiple"
                              element={<CreateMulti darkMode={darkMode} />}
                            />
                            <Route
                              path="/trending-creators/:id"
                              element={<CreatorsDetails darkMode={darkMode} />}
                            />
                            <Route
                              path="/profile/refer-creators"
                              element={<ReferCreators darkMode={darkMode} />}
                            />
                            <Route
                              path="/profile/refer-buyers"
                              element={<ReferBuyers darkMode={darkMode} />}
                            />
                            <Route
                              path="/privacy-policy"
                              element={<PrivacyPolicy darkMode={darkMode} />}
                            />
                            <Route
                              path="/layer2"
                              element={<Layer2 darkMode={darkMode} />}
                            />
                            <Route
                              path="/terms-and-condition"
                              element={
                                <TermsAndCondition darkMode={darkMode} />
                              }
                            />
                            <Route
                              path="/frequently-asked-questions"
                              element={<FAQ darkMode={darkMode} />}
                            />
                            <Route
                              path="/contact-us"
                              element={
                                <ContactUs
                                  darkMode={darkMode}
                                  socket={socket}
                                />
                              }
                            />
                            <Route
                              path="/contact"
                              element={
                                <ContactUs
                                  darkMode={darkMode}
                                  socket={socket}
                                />
                              }
                            />
                          </Routes>
                        </Suspense>
                        {!isMobile && <Footer darkMode={darkMode} />}
                      </Layout>
                    </OuterContext.Provider>
                  </MpDataContext.Provider>
                </BrowserRouter>
              </Box>
            </CookiesProvider>
          </div>
        </ThemeProvider>
        {/* </ThirdwebProvider> */}
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
