import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180 180"
    width={100}
    height={100}
    {...props}
  >
    <mask id="a">
      <rect width={180} height={180} rx={0} ry={0} fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <g transform="translate(0 66)">
        <g opacity={0.9} stroke="#2A3544">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.5 41C41.613 41 49 32.941 49 23S41.613 5 32.5 5 16 13.059 16 23s7.387 18 16.5 18Z"
            strokeWidth={6}
          />
          <path
            d="M29.515 36.765c-7.413 4.28-17.005 1.546-21.423-6.107"
            strokeWidth={4}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 67c9.389 0 17-6.492 17-14.5S37.389 38 28 38s-16 6.492-16 14.5S18.611 67 28 67Z"
            strokeWidth={4}
          />
          <path
            d="M168.606 60.423C164.326 53.01 154.653 50.582 147 55"
            strokeWidth={4}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M148 38c9.389 0 17-6.94 17-15.5 0-8.56-7.611-15.5-17-15.5s-16 6.94-16 15.5c0 8.56 6.611 15.5 16 15.5Z"
            strokeWidth={6}
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M145 0a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-12ZM23 27a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V29a2 2 0 0 0-2-2H23Zm1 33a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H24Zm119-16a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V44Z"
          fill="#273951"
        />
        <mask
          id="b"
          
          maskUnits="userSpaceOnUse"
          x={21}
          y={0}
          width={138}
          height={72}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M145 0a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-12ZM23 27a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V29a2 2 0 0 0-2-2H23Zm1 33a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H24Zm119-16a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V44Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#b)">
          <path fill="#7E57C2" d="M0 0h180v76H0z" />
        </g>
      </g>
      <g transform="translate(41)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.21 40C78.85 33.293 84.631 20.688 84 14c-.136-1.447 2-1.7 3 0 4.418 7.512 2.717 22.067 2.153 26H92v12H66V40h5.21ZM16.521 13.74c0 7.533 4.97 19.705 12.74 26.26H34v12H8V40h3.225c-.595-3.559-2.696-18.399 2.209-25.9.901-1.378 3.087-1.42 3.087-.36Z"
          fill="#E1E6E8"
        />
        <mask
          id="c"
          
          maskUnits="userSpaceOnUse"
          x={8}
          y={12}
          width={84}
          height={40}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71.21 40C78.85 33.293 84.631 20.688 84 14c-.136-1.447 2-1.7 3 0 4.418 7.512 2.717 22.067 2.153 26H92v12H66V40h5.21ZM16.521 13.74c0 7.533 4.97 19.705 12.74 26.26H34v12H8V40h3.225c-.595-3.559-2.696-18.399 2.209-25.9.901-1.378 3.087-1.42 3.087-.36Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#c)">
          <path fill="#7E57C2" d="M0 0h100v52H0z" />
          <path fillOpacity={0.4} d="M0 40h100v12H0z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.456 13h16.113v27H20.82c-7.449-7.85-5.364-27-5.364-27ZM84.82 13h7.75v27H81.82c5.751-7.805 3-27 3-27Z"
            fill="#fff"
            fillOpacity={0.4}
          />
        </g>
      </g>
      <g transform="translate(25 44)">
        <path
          d="M0 12C0 5.373 5.373 0 12 0h106c6.627 0 12 5.373 12 12v70c0 20.987-17.013 38-38 38H38c-20.987 0-38-17.013-38-38V12Z"
          fill="#0076DE"
        />
        <mask
          id="d"
          
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={130}
          height={120}
        >
          <path
            d="M0 12C0 5.373 5.373 0 12 0h106c6.627 0 12 5.373 12 12v70c0 20.987-17.013 38-38 38H38c-20.987 0-38-17.013-38-38V12Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#d)">
          <path fill="#5E35B1" d="M-2-2h134v124H-2z" />
        </g>
      </g>
      <g transform="translate(52 124)" fillOpacity={0.6}>
        <rect x={28} y={10} width={6} height={14} rx={2} />
        <rect x={14} y={10} width={6} height={14} rx={2} />
        <rect x={42} y={10} width={6} height={14} rx={2} />
        <rect x={56} y={10} width={6} height={14} rx={2} />
      </g>
      <g transform="translate(38 76)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 42c9.941 0 18-8.059 18-18S37.941 6 28 6s-18 8.059-18 18 8.059 18 18 18ZM74 42c9.941 0 18-8.059 18-18S83.941 6 74 6s-18 8.059-18 18 8.059 18 18 18Z"
          fillOpacity={0.2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 39c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15ZM74 39c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15Z"
          fill="#F1EEDA"
        />
        <rect x={26} y={15} width={10} height={10} rx={2} fillOpacity={0.8} />
        <rect x={74} y={15} width={10} height={10} rx={2} fillOpacity={0.8} />
      </g>
    </g>
  </svg>
)

export default SvgComponent
