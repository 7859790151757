import React, { useContext } from "react";
import AnimatedLoader from "../../Utils/AnimatedLoader/AnimatedLoader";
import { CircularProgress } from "@mui/material";
import MpDataContext from "../../Utils/Contexts/MpDataContext";

const SuspenseFallback = () => {
  const mpData = useContext(MpDataContext);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {mpData.mpHost === "genzers.io" ? (
        <AnimatedLoader />
      ) : (
        <CircularProgress color="secondary"></CircularProgress>
      )}
    </div>
  );
};

export default SuspenseFallback;
