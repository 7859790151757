import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180 180"
    width={100}
    height={100}
    {...props}
  >
    <mask id="a">
      <rect width={180} height={180} rx={0} ry={0} fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <g transform="translate(0 66)">
        <g opacity={0.9} stroke="#2A3544">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.5 41C41.613 41 49 32.941 49 23S41.613 5 32.5 5 16 13.059 16 23s7.387 18 16.5 18Z"
            strokeWidth={6}
          />
          <path
            d="M29.515 36.765c-7.413 4.28-17.005 1.546-21.423-6.107"
            strokeWidth={4}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 67c9.389 0 17-6.492 17-14.5S37.389 38 28 38s-16 6.492-16 14.5S18.611 67 28 67Z"
            strokeWidth={4}
          />
          <path
            d="M168.606 60.423C164.326 53.01 154.653 50.582 147 55"
            strokeWidth={4}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M148 38c9.389 0 17-6.94 17-15.5 0-8.56-7.611-15.5-17-15.5s-16 6.94-16 15.5c0 8.56 6.611 15.5 16 15.5Z"
            strokeWidth={6}
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M145 0a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-12ZM23 27a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V29a2 2 0 0 0-2-2H23Zm1 33a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H24Zm119-16a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V44Z"
          fill="#273951"
        />
        <mask
          id="b"
          
          maskUnits="userSpaceOnUse"
          x={21}
          y={0}
          width={138}
          height={72}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M145 0a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-12ZM23 27a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V29a2 2 0 0 0-2-2H23Zm1 33a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H24Zm119-16a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V44Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#b)">
          <path fill="#FF7043" d="M0 0h180v76H0z" />
        </g>
      </g>
      <g transform="translate(41)">
        <g filter="url(#c)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 33c0-11.046 8.954-20 20-20s20 8.954 20 20v11H30V33Z"
            fill="#fff"
            fillOpacity={0.3}
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 36c2.21 0 4-.972 4-4.286C54 28.401 52.21 24 50 24s-4 4.4-4 7.714S47.79 36 50 36Z"
          fill="#fff"
          fillOpacity={0.6}
        />
        <path
          d="M50 14.5a1 1 0 1 0 0 2v-2Zm11.694 7.188a1 1 0 1 0 1.482-1.343l-1.482 1.343Zm4.066 2.36a1 1 0 1 0-1.773.926l1.773-.927Zm-.335 4.908a1 1 0 0 0 1.956-.419l-1.956.419ZM50 16.5c4.638 0 8.806 2 11.694 5.188l1.482-1.343A17.73 17.73 0 0 0 50 14.5v2Zm13.987 8.474a15.657 15.657 0 0 1 1.438 3.982l1.956-.419a17.66 17.66 0 0 0-1.621-4.49l-1.773.927Z"
          fill="#fff"
        />
        <rect x={20} y={36} width={60} height={16} rx={1} fill="#FF7043" />
        <defs>
          <filter
            id="c"
            x={22}
            y={5}
            width={56}
            height={47}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={4} />
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={2} />
            <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
            <feBlend in2="shape" result="effect2_innerShadow" />
          </filter>
        </defs>
      </g>
      <g transform="translate(25 44)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 31c0 .02.014-.184 0-1 .183-.477.402-1.497 1-3 .827-3.056 2.78-6.556 6-10C16.694 6.6 35.172 0 65 0s48.306 6.6 58 17c3.22 3.444 5.173 6.944 6 10 .598 1.503.817 2.524 1 3-.014.816 0 1.02 0 1v39c0-.104-.028.501 0 1-.261 2.117-.529 4.015-1 6-1.186 5.991-3.394 11.911-7 17-9.717 16.337-27.745 26-57 26s-47.284-9.662-57-26c-3.606-5.09-5.813-11.009-7-17-.471-1.985-.739-3.883-1-6 .028-.453 0-1.323 0-1V31Z"
          fill="#E1E6E8"
        />
        <mask
          id="d"
          
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={130}
          height={120}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 31c0 .02.014-.184 0-1 .183-.477.402-1.497 1-3 .827-3.056 2.78-6.556 6-10C16.694 6.6 35.172 0 65 0s48.306 6.6 58 17c3.22 3.444 5.173 6.944 6 10 .598 1.503.817 2.524 1 3-.014.816 0 1.02 0 1v39c0-.104-.028.501 0 1-.261 2.117-.529 4.015-1 6-1.186 5.991-3.394 11.911-7 17-9.717 16.337-27.745 26-57 26s-47.284-9.662-57-26c-3.606-5.09-5.813-11.009-7-17-.471-1.985-.739-3.883-1-6 .028-.453 0-1.323 0-1V31Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#d)">
          <path fill="#F4511E" d="M-4-2h138v124H-4z" />
        </g>
      </g>
      <rect
        x={24}
        y={6}
        width={27}
        height={8}
        rx={4}
        fillOpacity={0.8}
        transform="translate(52 124)"
      />
      <g fillRule="evenodd" clipRule="evenodd" fill="#fff">
        <path d="M62 112a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM118 112a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" />
      </g>
    </g>
  </svg>
)

export default SvgComponent
