import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180 180"
    width={100}
    height={100}
    {...props}
  >
    <mask id="a">
      <rect width={180} height={180} rx={0} ry={0} fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <g transform="translate(0 66)">
        <g opacity={0.9} stroke="#2A3544">
          <path
            d="M38 12c-2.954 11.697-19.904 6.666-23.369 18.009C11.167 41.352 22.657 50 32.155 50"
            strokeWidth={6}
          />
          <path
            d="M150 55c8.394 3.486 20.102-7.594 16-16.5-4.102-8.906-16-6.694-16-19.305"
            strokeWidth={4}
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138 6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h19a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-19ZM21 37a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H23a2 2 0 0 1-2-2V37Zm115 7a2 2 0 0 1 2-2h19a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-19a2 2 0 0 1-2-2V44Z"
          fill="#273951"
        />
        <mask
          id="b"
          
          maskUnits="userSpaceOnUse"
          x={21}
          y={6}
          width={138}
          height={58}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138 6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h19a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-19ZM21 37a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H23a2 2 0 0 1-2-2V37Zm115 7a2 2 0 0 1 2-2h19a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-19a2 2 0 0 1-2-2V44Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#b)">
          <path fill="#FF7043" d="M0 0h180v76H0z" />
        </g>
      </g>
      <g transform="translate(41)">
        <g filter="url(#c)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 24c0-8.837 7.163-16 16-16h4c8.837 0 16 7.163 16 16v8a8 8 0 0 1-8 8H40a8 8 0 0 1-8-8v-8Z"
            fill="#fff"
            fillOpacity={0.3}
          />
        </g>
        <path
          d="M49 11.5c4.931 0 9.366 2.128 12.435 5.516"
          stroke="#fff"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m49.828 29-9-9L38 22.828l10 10V40h4v-7.03l10.142-10.142L59.314 20l-9 9h-.486Z"
          fill="#fff"
          fillOpacity={0.8}
        />
        <rect x={22} y={40} width={56} height={12} rx={1} fill="#FF7043" />
        <defs>
          <filter
            id="c"
            x={24}
            y={0}
            width={52}
            height={48}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={4} />
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={2} />
            <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
            <feBlend in2="shape" result="effect2_innerShadow" />
          </filter>
        </defs>
      </g>
      <g transform="translate(25 44)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 102V68.852a40 40 0 0 1 2.28-13.313l17.481-43.53A18 18 0 0 1 36.735 0h56.53a18.001 18.001 0 0 1 16.974 12.01l17.481 43.529A40.006 40.006 0 0 1 130 68.852V102c0 9.941-8.059 18-18 18H18c-9.941 0-18-8.059-18-18Z"
          fill="#0076DE"
        />
        <mask
          id="d"
          
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={130}
          height={120}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 102V68.852a40 40 0 0 1 2.28-13.313l17.481-43.53A18 18 0 0 1 36.735 0h56.53a18.001 18.001 0 0 1 16.974 12.01l17.481 43.529A40.006 40.006 0 0 1 130 68.852V102c0 9.941-8.059 18-18 18H18c-9.941 0-18-8.059-18-18Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#d)">
          <path fill="#F4511E" d="M-2-2h134v124H-2z" />
        </g>
      </g>
      <g transform="translate(52 124)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 10.222C18 21.785 24.474 28 38 28c13.518 0 20-6.338 20-17.778C58 9.496 57.174 8 55 8H21c-2.051 0-3 1.385-3 2.222Z"
          fillOpacity={0.8}
        />
        <mask
          id="e"
          
          maskUnits="userSpaceOnUse"
          x={18}
          y={8}
          width={40}
          height={20}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 10.222C18 21.785 24.474 28 38 28c13.518 0 20-6.338 20-17.778C58 9.496 57.174 8 55 8H21c-2.051 0-3 1.385-3 2.222Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#e)">
          <rect x={30} y={2} width={16} height={14} rx={2} fill="#fff" />
        </g>
      </g>
      <g transform="translate(38 76)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 42c9.941 0 18-8.059 18-18S37.941 6 28 6s-18 8.059-18 18 8.059 18 18 18ZM74 42c9.941 0 18-8.059 18-18S83.941 6 74 6s-18 8.059-18 18 8.059 18 18 18Z"
          fillOpacity={0.2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 39c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15ZM74 39c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15Z"
          fill="#F1EEDA"
        />
        <rect x={26} y={15} width={10} height={10} rx={2} fillOpacity={0.8} />
        <rect x={74} y={15} width={10} height={10} rx={2} fillOpacity={0.8} />
      </g>
    </g>
  </svg>
)

export default SvgComponent
