import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180 180"
    width={100}
    height={100}
    {...props}
  >
    <mask id="a">
      <rect width={180} height={180} rx={0} ry={0} fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <g transform="translate(0 66)">
        <g opacity={0.9} stroke="#2A3544">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.5 41C41.613 41 49 32.941 49 23S41.613 5 32.5 5 16 13.059 16 23s7.387 18 16.5 18Z"
            strokeWidth={6}
          />
          <path
            d="M29.515 36.765c-7.413 4.28-17.005 1.546-21.423-6.107"
            strokeWidth={4}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 67c9.389 0 17-6.492 17-14.5S37.389 38 28 38s-16 6.492-16 14.5S18.611 67 28 67Z"
            strokeWidth={4}
          />
          <path
            d="M168.606 60.423C164.326 53.01 154.653 50.582 147 55"
            strokeWidth={4}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M148 38c9.389 0 17-6.94 17-15.5 0-8.56-7.611-15.5-17-15.5s-16 6.94-16 15.5c0 8.56 6.611 15.5 16 15.5Z"
            strokeWidth={6}
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M145 0a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-12ZM23 27a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V29a2 2 0 0 0-2-2H23Zm1 33a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H24Zm119-16a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V44Z"
          fill="#273951"
        />
        <mask
          id="b"
          
          maskUnits="userSpaceOnUse"
          x={21}
          y={0}
          width={138}
          height={72}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M145 0a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-12ZM23 27a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V29a2 2 0 0 0-2-2H23Zm1 33a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H24Zm119-16a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V44Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#b)">
          <path fill="#FF7043" d="M0 0h180v76H0z" />
        </g>
      </g>
      <g transform="translate(41)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.8 28.397c-7.811-7.81-7.811-20.474 0-28.284l13.434 13.435 6.453-6.453a4.001 4.001 0 0 1 6.275-4.861 4 4 0 0 1-4.86 6.275l-6.454 6.453 13.435 13.435c-5.45 5.45-13.263 7.097-20.142 4.94v18.776h-4V31.58a20.02 20.02 0 0 1-4.142-3.184Z"
          fill="#E1E6E8"
        />
        <mask
          id="c"
          
          maskUnits="userSpaceOnUse"
          x={37}
          y={0}
          width={36}
          height={53}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.8 28.397c-7.811-7.81-7.811-20.474 0-28.284l13.434 13.435 6.453-6.453a4.001 4.001 0 0 1 6.275-4.861 4 4 0 0 1-4.86 6.275l-6.454 6.453 13.435 13.435c-5.45 5.45-13.263 7.097-20.142 4.94v18.776h-4V31.58a20.02 20.02 0 0 1-4.142-3.184Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#c)">
          <path fill="#FF7043" d="M0 0h100v52H0z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.799.113c-7.81 7.81-7.81 20.474 0 28.284 7.81 7.81 20.474 7.81 28.284 0"
            fill="#fff"
            fillOpacity={0.2}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.305 7.89a4 4 0 1 0 5.657-5.656 4 4 0 0 0-5.657 5.657Z"
            fill="#fff"
            fillOpacity={0.8}
          />
        </g>
      </g>
      <g transform="translate(25 44)">
        <path
          d="M0 12C0 5.373 5.373 0 12 0h106c6.627 0 12 5.373 12 12v70c0 20.987-17.013 38-38 38H38c-20.987 0-38-17.013-38-38V12Z"
          fill="#0076DE"
        />
        <mask
          id="d"
          
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={130}
          height={120}
        >
          <path
            d="M0 12C0 5.373 5.373 0 12 0h106c6.627 0 12 5.373 12 12v70c0 20.987-17.013 38-38 38H38c-20.987 0-38-17.013-38-38V12Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#d)">
          <path fill="#F4511E" d="M-2-2h134v124H-2z" />
        </g>
      </g>
      <rect
        x={16}
        y={8}
        width={44}
        height={4}
        rx={2}
        fillOpacity={0.8}
        transform="translate(52 124)"
      />
      <g transform="translate(38 76)">
        <rect y={12} width={104} height={32} rx={16} fillOpacity={0.8} />
        <rect x={24} y={22} width={10} height={12} rx={2} fill="#F4F4F4" />
        <rect x={70} y={22} width={10} height={12} rx={2} fill="#F4F4F4" />
      </g>
    </g>
  </svg>
)

export default SvgComponent;
