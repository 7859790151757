import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180 180"
    width={100}
    height={100}
    {...props}
  >
    <mask id="a">
      <rect width={180} height={180} rx={0} ry={0} fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <g transform="translate(0 66)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 39c0 11.926 5.954 22 16 22s20-10.074 20-22c0-12.926-9.954-23-20-23S12 26.074 12 39Zm156 0c0 11.926-5.954 22-16 22s-20-10.074-20-22c0-12.926 9.954-23 20-23s16 10.074 16 23Z"
          fill="#E1E6E8"
        />
        <mask
          id="b"
          
          maskUnits="userSpaceOnUse"
          x={12}
          y={16}
          width={156}
          height={45}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 39c0 11.926 5.954 22 16 22s20-10.074 20-22c0-12.926-9.954-23-20-23S12 26.074 12 39Zm156 0c0 11.926-5.954 22-16 22s-20-10.074-20-22c0-12.926 9.954-23 20-23s16 10.074 16 23Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#b)">
          <path fill="#7E57C2" d="M0 0h180v76H0z" />
          <path fillOpacity={0.2} d="M20 0h140v76H20z" />
        </g>
      </g>
      <g transform="translate(41)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 13c-11.046 0-20 8.954-20 20v3h-9a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h58a1 1 0 0 0 1-1V37a1 1 0 0 0-1-1h-9v-3c0-11.046-8.954-20-20-20Z"
          fill="#59C4FF"
        />
        <mask
          id="c"
          
          maskUnits="userSpaceOnUse"
          x={20}
          y={13}
          width={60}
          height={39}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50 13c-11.046 0-20 8.954-20 20v3h-9a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h58a1 1 0 0 0 1-1V37a1 1 0 0 0-1-1h-9v-3c0-11.046-8.954-20-20-20Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#c)">
          <path fill="#7E57C2" d="M0 0h100v52H0z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50 36c2.21 0 4-.972 4-4.286C54 28.401 52.21 24 50 24s-4 4.4-4 7.714S47.79 36 50 36Z"
            fill="#fff"
            fillOpacity={0.6}
          />
          <path fill="#fff" fillOpacity={0.4} d="M20 13h60v23H20z" />
          <path
            d="M50 14.5a1 1 0 1 0 0 2v-2Zm11.694 7.188a1 1 0 1 0 1.482-1.343l-1.482 1.343Zm4.066 2.36a1 1 0 1 0-1.773.926l1.773-.927Zm-.335 4.908a1 1 0 0 0 1.956-.419l-1.956.419ZM50 16.5c4.638 0 8.806 2 11.694 5.188l1.482-1.343A17.73 17.73 0 0 0 50 14.5v2Zm13.987 8.474a15.657 15.657 0 0 1 1.438 3.982l1.956-.419a17.66 17.66 0 0 0-1.621-4.49l-1.773.927Z"
            fill="#fff"
          />
        </g>
      </g>
      <g transform="translate(25 44)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66 0c58.352 0 64.001 40.685 64 78-.001 33.315-25.466 42-64 42-37.461 0-66-8.685-66-42C0 40.685 7.648 0 66 0Z"
          fillOpacity={0.8}
        />
        <mask
          id="d"
          
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={130}
          height={120}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66 0c58.352 0 64.001 40.685 64 78-.001 33.315-25.466 42-64 42-37.461 0-66-8.685-66-42C0 40.685 7.648 0 66 0Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#d)">
          <path fill="#5E35B1" d="M-4-2h138v124H-4z" />
        </g>
      </g>
      <g transform="translate(52 124)">
        <rect x={4} y={4} width={68} height={24} rx={5} fillOpacity={0.2} />
        <rect x={8} y={8} width={60} height={16} rx={2} fillOpacity={0.8} />
        <path
          d="M9 17h11l2-4 3 7 4-8 2 9 3-11 3 10 3-3h15l3-4 2 7 3-3h4"
          stroke="#4EFAC9"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g transform="translate(38 76)">
        <rect y={11} width={104} height={34} rx={17} fillOpacity={0.8} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 41c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13ZM75 41c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13Z"
          fill="#F1EEDA"
        />
        <rect x={24} y={23} width={10} height={10} rx={2} fillOpacity={0.8} />
        <rect x={70} y={23} width={10} height={10} rx={2} fillOpacity={0.8} />
      </g>
    </g>
  </svg>
)

export default SvgComponent
